import WormholeConnect from "@wormhole-foundation/wormhole-connect";

const config: WormholeConnectConfig = {
  networks: ["ethereum", "arbitrum", "solana"],
  rpcs: {
    solana:
      "https://swissborg.rpcpool.com/b96234be-2d36-4600-b0d9-2f222666ac9e",
    ethereum: "https://rpc.ankr.com/eth",
    arbitrum: "https://rpc.ankr.com/arbitrum",
  },
  walletConnectProjectId: "9a573760ed6fedef278c994255bc7816",
  tokens: ["XBGeth", "XBGsolana", "XBGarbitrum"],
  tokensConfig: {
    XBGeth: {
      key: "XBGeth",
      symbol: "XBG",
      nativeChain: "ethereum",
      tokenId: {
        chain: "ethereum",
        address: "0xEaE00D6F9B16Deb1BD584c7965e4c7d762f178a1",
      },
      icon: "https://bafybeiemq2qpk3fahebltwzbdsugrzhxuono3ugxa25imywc7mbfhwl2lm.ipfs.dweb.link/",
      coinGeckoId: "",
      color: "#FFFFFF",
      decimals: {
        default: 18,
      },
      foreignAssets: {
        solana: {
          address: "XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY",
          decimals: 9,
        },
        arbitrum: {
          address: "0x93FA0B88C0C78e45980Fa74cdd87469311b7B3E4",
          decimals: 18,
        },
      },
    },
    XBGsolana: {
      key: "XBGsolana",
      symbol: "XBG",
      nativeChain: "solana",
      tokenId: {
        chain: "solana",
        address: "XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY",
      },
      icon: "https://bafybeiemq2qpk3fahebltwzbdsugrzhxuono3ugxa25imywc7mbfhwl2lm.ipfs.dweb.link/",
      coinGeckoId: "",
      color: "#FFFFF",
      decimals: {
        default: 9,
      },
    },
    XBGarbitrum: {
      key: "XBGarbitrum",
      symbol: "XBG",
      nativeChain: "arbitrum",
      tokenId: {
        chain: "arbitrum",
        address: "0x93FA0B88C0C78e45980Fa74cdd87469311b7B3E4",
      },
      icon: "https://bafybeiemq2qpk3fahebltwzbdsugrzhxuono3ugxa25imywc7mbfhwl2lm.ipfs.dweb.link/",
      coinGeckoId: "",
      color: "#FFFFF",
      decimals: {
        default: 18,
      },
    },
  },
  nttGroups: {
    SOLANA_XBG: {
      nttManagers: [
        {
          chainName: "ethereum",
          address: "0xa4489105efa4b029485d6bd3A4f52131baAE4B1B",
          tokenKey: "XBGeth",
          transceivers: [
            {
              address: "0x7135766F279b9A50F7A7199cfF1be284521a0409",
              type: "wormhole",
            },
          ],
        },
        {
          chainName: "solana",
          address: "NttXP2tPLxGkNA3yrSfFZbtDbfKPKBrJUR6Jcqh6sRi",
          tokenKey: "XBGsolana",
          transceivers: [
            {
              address: "NttXP2tPLxGkNA3yrSfFZbtDbfKPKBrJUR6Jcqh6sRi",
              type: "wormhole",
            },
          ],
        },
        {
          chainName: "arbitrum",
          address: "0x7135766F279b9A50F7A7199cfF1be284521a0409",
          tokenKey: "XBGarbitrum",
          transceivers: [
            {
              address: "0x874303ba6a34fC33ADcdFFd4293a41f32246D6a0",
              type: "wormhole",
            },
          ],
        },
      ],
    },
  },
};

const theme: WormholeConnectPartialTheme = {
  background: {
    default: "#00000",
  },
};

function App() {
  return <WormholeConnect config={config} theme={theme} />;
}

export default App;
